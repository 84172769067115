/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import PageHeader from "./pageHeader"
import "./layout.css"
import { ThemeProvider } from "styled-components"
import { Helmet } from "react-helmet"
import PageFooter from "./pageFooter"
import { useStaticQuery, graphql } from "gatsby"
import BaseImg from "./baseImg"
import styled from "styled-components"
import { useAnimatedBackground } from "../helpers"

const BackgroundShapes = styled(BaseImg)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  object-fit: cover;
  margin: 0;
  height: 7173px;
  width: 2433px;
`

const theme = { primary: "#323E5B", secondary: "#F0565E", lightGray: "#E6E6E6" }

const Layout = ({ children, marginTop }) => {
  const [backgroundRef] = useAnimatedBackground()
  const { file } = useStaticQuery(graphql`
    query {
      file(name: { eq: "shapes" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        publicURL
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Hind:400,700|Montserrat:300,400,600,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <BackgroundShapes ref={backgroundRef} localFile={file} alt="" />
      <ThemeProvider theme={theme}>
        <PageHeader />
        <main style={{ marginTop: marginTop ? marginTop : "120px" }}>
          {children}
        </main>
        <PageFooter />
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
