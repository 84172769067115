import React from "react"
import styled from "styled-components"
import decodeHtmlCharCodes from "../utils/decodeHtmlCharCodes"
import { Link } from "gatsby"

const SubmenuContainer = styled.div`
  position: absolute;
  top: 35px;
  left: 0;
  padding: 1.5rem;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 2;
  display: none;
  flex-direction: column;
  white-space: nowrap;
  a + a {
    padding-top: 0.375rem;
  }
  a {
    display: block;
  }
  @media (max-width: 1195px) {
    position: static;
    margin-top: 0.5rem;
    white-space: normal;
    width: 100%;
    a + a {
      padding-top: 1rem;
    }
  }
`

const MoreButton = styled.span`
  background: none;
  border: none;
  position: relative;
  color: #fff;
  padding: 0 5px;
  cursor: pointer;
  :focus {
    outline: none;
    ::before {
      border-color: ${(props) => props.theme.secondary};
    }
  }
  ::before {
    content: "";
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-bottom: 2px ${(props) => props.theme.primary} solid;
    border-left: 2px ${(props) => props.theme.primary} solid;
    transform: translate(-50%, -70%) rotate(-45deg);
    position: absolute;
  }
`

const HeaderSubmenu = ({ menuItems }) => {
  return (
    <>
      <MoreButton aria-label="Open submenu">{">"}</MoreButton>
      <SubmenuContainer className="header-submenu">
        {menuItems.map((menuItem) => (
          <Link
            key={menuItem.title}
            to={menuItem.url.replace(/http.*\/\/.*?\//g, "/")}
          >
            {decodeHtmlCharCodes(menuItem.title)}
          </Link>
        ))}
      </SubmenuContainer>
    </>
  )
}

export default HeaderSubmenu
